import React from 'react';

const ChatBotFooter = () => {
  return (
    <div className="chatbot-footer">
      <a href="https://www.omnicell.com/privacy" target="_blank" rel="noopener noreferrer">
        Omnicell Privacy Statement
      </a>
      <a
        href="https://enlivenhealth.co/enlivenhealth-website-terms-and-conditions/"
        target="_blank"
        rel="noopener noreferrer"
      >
        EH Terms and Conditions
      </a>
    </div>
  );
};

export default ChatBotFooter;
